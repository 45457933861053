import React from "react";
import "./Footer.css";
import Colophon from "../Colophon/Colophon";

function Footer() {
  return (
    <div className="footer">
      <div className="footerContainer">
        <svg
          className="logo"
          width="100"
          height="100"
          viewBox="0 0 100 100"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M94.4056 2.83688H5.59441C4.04955 2.83688 2.7972 4.10699 2.7972 5.67376V94.3262C2.7972 95.893 4.04955 97.1631 5.59441 97.1631H94.4056C95.9504 97.1631 97.2028 95.893 97.2028 94.3262V5.67376C97.2028 4.107 95.9504 2.83688 94.4056 2.83688ZM5.59441 0C2.5047 0 0 2.54023 0 5.67376V94.3262C0 97.4598 2.5047 100 5.59441 100H94.4056C97.4953 100 100 97.4598 100 94.3262V5.67376C100 2.54023 97.4953 0 94.4056 0H5.59441Z"
          />
          <path d="M24.828 22.8085C25.4881 22.9333 26.0308 23.2681 26.4559 23.8128C26.8811 24.3574 27.0937 24.9816 27.0937 25.6851C27.0937 26.3206 26.9371 26.8823 26.6238 27.3702C26.3217 27.8468 25.8797 28.2213 25.2979 28.4936C24.7161 28.766 24.028 28.9021 23.2336 28.9021H18.1818V17.0213H23.0154C23.8098 17.0213 24.4923 17.1518 25.0629 17.4128C25.6448 17.6738 26.0811 18.0369 26.372 18.5021C26.6741 18.9674 26.8252 19.495 26.8252 20.0851C26.8252 20.7773 26.6406 21.356 26.2713 21.8213C25.9133 22.2865 25.4322 22.6156 24.828 22.8085ZM20.5315 21.9234H22.6797C23.2392 21.9234 23.6699 21.7986 23.972 21.5489C24.2741 21.2879 24.4252 20.9192 24.4252 20.4426C24.4252 19.966 24.2741 19.5972 23.972 19.3362C23.6699 19.0752 23.2392 18.9447 22.6797 18.9447H20.5315V21.9234ZM22.8979 26.9617C23.4685 26.9617 23.9105 26.8255 24.2238 26.5532C24.5483 26.2809 24.7105 25.895 24.7105 25.3957C24.7105 24.8851 24.5427 24.4879 24.207 24.2043C23.8713 23.9092 23.4182 23.7617 22.8476 23.7617H20.5315V26.9617H22.8979Z" />
          <path d="M49.2028 18.9617V21.9404H53.1469V23.8298H49.2028V26.9787H53.6504V28.9192H46.8531V17.0213H53.6504V18.9617H49.2028Z" />
          <path d="M82.3161 17.0213L78.3552 24.766V28.9021H76.0056V24.766L72.028 17.0213H74.6797L77.1972 22.4511L79.6979 17.0213H82.3161Z" />
          <path d="M22.7916 56.1418C21.6951 56.1418 20.6881 55.8809 19.7706 55.3589C18.8531 54.8369 18.1259 54.1163 17.5888 53.1972C17.0517 52.2667 16.7832 51.217 16.7832 50.0482C16.7832 48.8908 17.0517 47.8525 17.5888 46.9333C18.1259 46.0028 18.8531 45.2766 19.7706 44.7546C20.6881 44.2326 21.6951 43.9716 22.7916 43.9716C23.8993 43.9716 24.9063 44.2326 25.8126 44.7546C26.7301 45.2766 27.4518 46.0028 27.9776 46.9333C28.5147 47.8525 28.7832 48.8908 28.7832 50.0482C28.7832 51.217 28.5147 52.2667 27.9776 53.1972C27.4518 54.1163 26.7301 54.8369 25.8126 55.3589C24.8951 55.8809 23.8881 56.1418 22.7916 56.1418ZM22.7916 54.0142C23.4965 54.0142 24.1175 53.8553 24.6545 53.5376C25.1916 53.2085 25.6112 52.7433 25.9133 52.1418C26.2154 51.5404 26.3664 50.8426 26.3664 50.0482C26.3664 49.2539 26.2154 48.5617 25.9133 47.9716C25.6112 47.3702 25.1916 46.9106 24.6545 46.5929C24.1175 46.2752 23.4965 46.1163 22.7916 46.1163C22.0867 46.1163 21.4601 46.2752 20.9119 46.5929C20.3748 46.9106 19.9552 47.3702 19.6531 47.9716C19.351 48.5617 19.2 49.2539 19.2 50.0482C19.2 50.8426 19.351 51.5404 19.6531 52.1418C19.9552 52.7433 20.3748 53.2085 20.9119 53.5376C21.4601 53.8553 22.0867 54.0142 22.7916 54.0142Z" />
          <path d="M55.4741 55.8695H53.1245L47.8042 47.7163V55.8695H45.4545V43.9716H47.8042L53.1245 52.1418V43.9716H55.4741V55.8695Z" />
          <path d="M76.1231 43.9716C77.3538 43.9716 78.4336 44.2156 79.3622 44.7035C80.3021 45.1915 81.0238 45.8894 81.5273 46.7972C82.042 47.6936 82.2993 48.7376 82.2993 49.9291C82.2993 51.1206 82.042 52.1645 81.5273 53.061C81.0238 53.9461 80.3021 54.6326 79.3622 55.1206C78.4336 55.6085 77.3538 55.8525 76.1231 55.8525H72.028V43.9716H76.1231ZM76.0392 53.8269C77.2699 53.8269 78.221 53.4865 78.8923 52.8057C79.5636 52.1248 79.8993 51.166 79.8993 49.9291C79.8993 48.6922 79.5636 47.7277 78.8923 47.0355C78.221 46.3319 77.2699 45.9801 76.0392 45.9801H74.3776V53.8269H76.0392Z" />
          <path d="M47.8042 70.922V78.2752C47.8042 79.0808 48.0112 79.6993 48.4252 80.1305C48.8392 80.5504 49.421 80.7603 50.1706 80.7603C50.9315 80.7603 51.5189 80.5504 51.9329 80.1305C52.3469 79.6993 52.5538 79.0808 52.5538 78.2752V70.922H54.9203V78.2582C54.9203 79.2681 54.7021 80.1248 54.2657 80.8284C53.8406 81.5206 53.2643 82.0426 52.5371 82.3943C51.821 82.7461 51.021 82.922 50.1371 82.922C49.2643 82.922 48.4699 82.7461 47.7538 82.3943C47.049 82.0426 46.4895 81.5206 46.0755 80.8284C45.6615 80.1248 45.4545 79.2681 45.4545 78.2582V70.922H47.8042Z" />
          <path d="M79.5636 82.8028L77.0294 78.8028L74.7301 82.8028H72.0783L75.7706 76.8113L72.028 70.922H74.7301L77.2643 74.905L79.5469 70.922H82.1986L78.5231 76.8965L82.2657 82.8028H79.5636Z" />
          <path d="M25.8741 76.5957C25.8741 78.1625 24.6218 79.4326 23.0769 79.4326C21.5321 79.4326 20.2797 78.1625 20.2797 76.5957C20.2797 75.029 21.5321 73.7589 23.0769 73.7589C24.6218 73.7589 25.8741 75.029 25.8741 76.5957Z" />
        </svg>
        <div className="footerContactSocials">
          <div className="footerLinkContainer">
            <div className="footerHeading">Find</div>
            <a
              href="https://www.linkedin.com/in/steven-odendaal-a09a69187"
              target="_blank"
              rel="noreferrer"
              className="footerLink"
            >
              Linkedin
            </a>
            <a
              href="https://dribbble.com/so128"
              target="_blank"
              rel="noreferrer"
              className="footerLink"
            >
              Dribbble
            </a>
            {/* <a href="" className="footerLink">
              Resume
            </a> */}
          </div>
          {/* <div className="footerEmailContainer">
            <div className="footerHeading">Contact</div>
            <div className="email">Email</div>
          </div> */}
        </div>
      </div>
      <div className="footerMadeby">
        © 2023 Steven Odendaal<div>•</div><Colophon />
      </div>
    </div>
  );
}

export default Footer;
